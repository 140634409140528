'use strict'

###*
 # @ngdoc object
 # @name mundoAuthentication.controller:MundoAuthenticationCtrl

 # @description

###
class MundoAuthenticationCtrl
  ### @ngInject ###
  constructor: (
    $log,
    $state,
    OAuth,
    OAuthToken,
    $rootScope,
    $translate,
    mundoConfiguration,
    $cookies,
    $window,
    $location
  ) ->
    @ctrlName = 'MundoAuthenticationCtrl'

    @entity =
      username: null
      password: null

    @errorMessage = null
    @loading = false

    @SAML2SSOEnabled = mundoConfiguration.saml2? and mundoConfiguration.saml2.identity_provider_entity_id?
    @nextState = if $rootScope.nextState? then $rootScope.nextState else null
    @nextState = if (not @nextState) or
      (@nextState.state == 'authentication.login') or
      (@nextState.state == 'authentication.logout') then {state: 'home', params: {}} else @nextState

    if ($state.current.name == 'authentication.login') and $rootScope.user?
      $log.debug 'Auth: Attempting redirect to home state because auth state appears valid, or router state is pristine'
      $state.go 'home'

    @loginCheck = ->
      @loading = true

      OAuth
        .getAccessToken(@entity)
        .then (result) =>
          @errorMessage = null

          # Clear user & pass
          @entity.username = null
          @entity.password = null

          @loading = false
          $state.go @nextState.state, @nextState.params
        , (result) =>
          @errorMessage = result.data.error_description
          @loading = false

    @logout = ->
      @loading = true

      OAuth
        .revokeToken()
        .then () =>
          @loading = false

          if $cookies.get 'previous_token'
            OAuthToken.setToken $cookies.getObject 'previous_token'
            $cookies.remove 'previous_token'
            homeUrl = window.location.protocol + "//" + window.location.host+ "/#/home/"
            $window.location = homeUrl
            $window.location.reload()
          else
            $rootScope.$broadcast 'mundo:authentication:logout'
            SAMLSSOEnabled = mundoConfiguration.saml2? and mundoConfiguration.saml2.identity_provider_entity_id?
            if SAMLSSOEnabled
              @performLogoutSAML2SSO()
            else
              $state.go 'authentication.init'

    @parseRedirectPayload = (payload) ->
      parsed = window.atob payload
      parsed = JSON.parse parsed

      tokenData = parsed.token
      OAuthToken.setToken tokenData

      redirectUrl = parsed.redirect
      window.location = redirectUrl

    @performLoginSAML2SSO = () ->
      baseUrl = mundoConfiguration.oauth.baseUrl
      redirectUrl = $state.href 'home', {}, {absolute: true}
      oauthClientId = mundoConfiguration.oauth.clientId
      tenantId = mundoConfiguration.tenant.id
      idp = mundoConfiguration.saml2.identity_provider_entity_id

      targetBaseUrl = window.btoa($location.absUrl().replace($location.url(), ''))
      redirectUrl = window.btoa redirectUrl
      oauthClientId = window.btoa oauthClientId
      tenantId = window.btoa tenantId

      url = "#{baseUrl}/saml/login_redirect/#{targetBaseUrl}/#{oauthClientId}/#{redirectUrl}?idp=#{idp}"
      window.location = url

    @performLogoutSAML2SSO = () ->
      baseUrl = mundoConfiguration.oauth.baseUrl
      redirectUrl = $state.href 'authentication.login', {}, {absolute: true}
      idp = window.btoa mundoConfiguration.saml2.identity_provider_entity_id

      redirectUrl = window.btoa redirectUrl

      url = "#{baseUrl}/saml/logout/#{idp}/#{redirectUrl}"

      window.location = url

    ## Method for redirecting to external logins if needed
    @loginRedirection = () ->
      SAMLSSOEnabled = mundoConfiguration.saml2? and mundoConfiguration.saml2.identity_provider_entity_id?
      SAMLSSOAutomaticRedirect = mundoConfiguration.saml2? and mundoConfiguration.saml2.automatic_redirect? \
      and mundoConfiguration.saml2.automatic_redirect
      if SAMLSSOEnabled and SAMLSSOAutomaticRedirect
        @performLoginSAML2SSO()
      else
        $state.go 'authentication.login'

angular
  .module('mundoAuthentication')
  .controller 'MundoAuthenticationCtrl', MundoAuthenticationCtrl
